<template>
  <div>
    <Banner
      bannerhead="Contenuti speciali"
      bannertext="Ascolta le testimonianze"
    />
    <v-container>
      <v-row v-if="loadedSpeakers">
        <v-col cols="12">
          <AlertPromotions />
          <slot />
        </v-col>

        <feed-card
          v-for="(speaker, index) in loadedSpeakers"
          :key="index"
          :speaker="speaker"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Speakers",
  title: "Speakers",

  components: {
    FeedCard: () => import("@/components/FeedCard"),
    Banner: () => import("@/components/Banner"),
    AlertPromotions: () => import("@/components/misc/AlertPromotions"),
  },

  data: () => ({}),

  computed: {
    loadedSpeakers() {
      return this.$store.getters.loadedSpeakers;
    },
  },
};
</script>
